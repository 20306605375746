type Props = { className?: string }

export const Logo = ({ className }: Props) => {
  return (
    <svg
      viewBox="0 0 800 194.7"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
      width={112}
      className={className}
    >
      <title>benuta</title>
      <path d="M287 131.7v-8.3c0-39.7-27.8-70.3-67-70.3-38.9 0-68.7 30.8-68.7 70.5 0 40 29.3 70.5 68.2 70.5 30.1 0 55.6-18.1 65.6-44.5h-28.7c-7.3 10.8-22.9 19.3-37 19.3-20.4-.4-36.6-16.6-39.9-37.3H287zm-106.3-23c5.6-17.6 20.8-30.2 39.2-30.2 18.7 0 33.5 12.6 39 30.2h-78.2zM417.4 102.9c-1.3-23.7-14.7-40.8-36.7-46.9-6.9-1.9-15.6-2.9-22-2.9s-15 1-21.9 2.9c-22 6.1-35.4 23.3-36.7 46.9-.9 17.3-.4 79.4-.4 87.8h27.7c0-5.7-.1-57.9.1-73.6 0-4.1.2-9.4 1.5-14.2 2.3-8.8 6.5-15.5 14.3-19.3 3.4-1.7 5.8-2.5 10.5-3.1 3.3-.4 6.7-.4 10.1 0 4.6.6 7.1 1.4 10.5 3.1 7.9 3.9 12.1 10.5 14.3 19.3 1.2 4.9 1.4 10.2 1.5 14.2.1 15.7.1 67.9.1 73.6H418c-.2-8.4.3-70.4-.6-87.8zM71.1 167.7c-23.5 0-41.4-19-41.4-43.8 0-25.1 18-44.1 41.4-44.1 23.8 0 41.4 19 41.4 44.1 0 24.8-17.7 43.8-41.4 43.8M68.8 52.6c-15.9 0-30.3 5.2-41.8 14.1v-67H0v123.6c0 40.2 30.3 71 68.8 71 38.8 0 69.1-30.8 69.1-71 0-39.9-30.3-70.7-69.1-70.7M432.3 144.5c1.3 23.7 14.7 40.8 36.7 46.9 6.9 1.9 15.6 2.9 22 2.9s15-1 21.9-2.9c22-6.1 35.4-23.3 36.7-46.9.9-17.3.4-79.4.4-87.8h-27.7c0 5.7.1 57.9-.1 73.6 0 4.1-.2 9.4-1.5 14.2-2.3 8.8-6.5 15.5-14.3 19.3-3.4 1.7-5.8 2.5-10.5 3.1-3.3.4-6.7.4-10.1 0-4.6-.6-7.1-1.4-10.5-3.1-7.9-3.9-12.1-10.5-14.3-19.3-1.2-4.9-1.4-10.2-1.5-14.2-.1-15.7-.1-67.9-.1-73.6h-27.7c.2 8.4-.4 70.4.6 87.8zM729.9 167.7c-23.5 0-41.4-19.1-41.4-43.8 0-25.1 18-44.1 41.4-44.1 23.8 0 41.4 19.1 41.4 44.1 0 24.8-17.7 43.8-41.4 43.8m70.1-43.9c0-39.9-30.3-70.7-69.1-70.7-38.5 0-68.8 30.8-68.8 70.7 0 40.2 30.3 71 68.8 71 16 0 30.4-5.2 42.1-14.2v10.1h27v-66.9" />
      <path d="M665.3 184.6c-8 6.3-19.9 10.1-31 10.1-10.4 0-20.8-2.8-28.4-8.4-11.1-8.1-16.8-19.9-17.1-35.1-.3-16.1-.2-69.5-.2-69.5H561v-25h27v-41h28v41h40v24h-39v64c-.4 9.8 2.2 16.5 8 20.3 6.2 4.1 16.3 4.1 24-2.3 1.6 3.4 3.8 7.4 6.9 11.6 3.3 4.3 6.6 7.7 9.4 10.3z" />
    </svg>
  )
}
